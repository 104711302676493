import React from 'react';
import _ from 'lodash';
import { graphql, navigate } from 'gatsby';
import Layout from 'components/Layout';
import SEO from 'components/seo';
import News from 'features/media/news';
import PRESS from 'src/utils/pressConstants';
import styles from './media.module.scss';
import HeroSection from 'components/HeroSection';
import { useContactPoint } from 'shared/queries/contactPoint';
import { generateContentfulAssetURL } from 'utils/image';

const Press = props => {
  const { pageContext } = props;
  const { contactPoint } = useContactPoint();
  const pressContact = props.data.allContentfulWebMediaPressOptions.edges
    .map(edge => edge.node.pressContact)
    .filter(contact => contact.entries?.length && contact)
    .map(entry => entry.entries[0])
    .filter(entry => entry.revision !== 0);
  const { numPages, currentPage } = pageContext;
  const posts = props.data.allContentfulPost.edges;
  const pageData = {
    ...props.data.allContentfulWebMediaPressOptions.edges[0].node,
    pressContact:
      pressContact?.length > 0
        ? _.find(contactPoint, ['contentful_id', pressContact[0].id])
        : {},
  };
  const state = props.location.state || {};

  const seoImage = {
    url: generateContentfulAssetURL(pageData?.heroImage?.image?.file?.url),
    width: pageData?.heroImage?.image?.file?.details?.image?.width,
    height: pageData?.heroImage?.image?.file?.details?.image?.height,
  };

  return (
    <div>
      <Layout transparentHeader={true} paddingTop={false}>
        <SEO
          title="Media"
          image={seoImage}
          metaTitle={pageData?.metaTitle}
          description={
            pageData?.metaDescription?.childMarkdownRemark?.rawMarkdownBody
          }
        />
        <div className={styles.media}>
          <HeroSection
            image={pageData?.heroImage?.image}
            title={pageData?.heroImage?.title}
            subTitle={pageData?.heroImage?.subTitle}
          />
          <News
            bio={pageData?.bio?.file?.url}
            posts={posts.map(post => {
              return {
                title: post?.node?.title,
                description: post?.node?.body?.json?.content.filter(node => {
                  return node.nodeType === 'paragraph';
                })[0].content[0].value,
                image: post?.node?.image?.fluid,
                url: `/press/article/${post?.node?.slug}`,
                imageDescription: post?.node?.image?.description,
              };
            })}
            tab={PRESS.press}
            pageData={pageData?.pressContact}
            pageNumber={currentPage}
            totalNumberOfPages={numPages}
            changePage={page => {
              navigate(
                page === 1
                  ? `/${PRESS.pressUrl}/`
                  : `/${PRESS.pressUrl}/${page}`,
                {
                  state: { internal: true },
                }
              );
            }}
            location={props.location}
          />
        </div>
      </Layout>
    </div>
  );
};
export default Press;

export const query = graphql`
  query getPosts($skip: Int!, $limit: Int!) {
    allContentfulPost(
      sort: { fields: publicationDate, order: DESC }
      limit: $limit
      skip: $skip
      filter: { hidden: { ne: true } }
    ) {
      edges {
        node {
          slug
          subtitle
          title
          body {
            json
          }
          image {
            fluid(maxWidth: 1800, maxHeight: 620, quality: 90) {
              ...GatsbyContentfulFluid_withWebp
            }
            description
          }
        }
      }
    }

    allContentfulWebMediaPressOptions {
      edges {
        node {
          title
          contentful_id
          bio {
            file {
              url
            }
          }
          heroImage {
            title
            subTitle
            image {
              fluid(maxWidth: 1800, quality: 100) {
                ...GatsbyContentfulFluid_withWebp
              }
              description
              file {
                url
                details {
                  image {
                    height
                    width
                  }
                }
              }
            }
          }
          metaTitle
          metaDescription {
            childMarkdownRemark {
              rawMarkdownBody
            }
          }
          pressContact {
            ... on contentfulWebMediaPressOptionsPressContactJsonNode {
              entries {
                description
                id
                revision
                title
              }
            }
          }
        }
      }
    }
  }
`;
